import { PostgrestError } from "@supabase/supabase-js";

export const isAnyVariableNull = (... variables: any[]): boolean => {
  return variables.some(
    (variable) => variable === null || variable === undefined
  )
}

export const isPostgrestError = (error: any): error is PostgrestError  => {
  return (
    error !== null &&
    typeof error === 'object' &&
    'message' in error &&
    'details' in error &&
    'hint' in error &&
    'code' in error
  );
}
